var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-list" },
    [
      _c("van-search", {
        attrs: {
          shape: "round",
          "left-icon": "",
          "show-action": "",
          placeholder: "请输入查询内容"
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.fileName,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "fileName", $$v)
          },
          expression: "searchForm.fileName"
        }
      }),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c(
            "div",
            {
              key: item.fileId,
              staticClass: "file-item",
              on: {
                click: function($event) {
                  return _vm.detail(item.fileId)
                }
              }
            },
            [
              _c("p", [
                _c("span", [_vm._v("附件名称：")]),
                _c("span", [_vm._v(_vm._s(item.fileName))])
              ]),
              _c("p", [
                _c("span", [_vm._v("上传人：")]),
                _c("span", [_vm._v(_vm._s(item.createUserName))]),
                _c("span", [_vm._v("上传时间：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.utils.dateTimeFormat(item.uploadTime)))
                ])
              ]),
              _c("van-divider")
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }