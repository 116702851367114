<template>
  <div class="file-list">
    <van-search
      v-model="searchForm.fileName"
      shape="round"
      left-icon=""
      show-action
      placeholder="请输入查询内容"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="item in list" :key="item.fileId" class="file-item" @click="detail(item.fileId)">
        <p>
          <span>附件名称：</span><span>{{ item.fileName }}</span>
        </p>
        <p>
          <span>上传人：</span><span>{{ item.createUserName }}</span>
          <span>上传时间：</span><span>{{ utils.dateTimeFormat(item.uploadTime) }}</span>
        </p>
        <van-divider />
      </div>
    </van-list>
  </div>
</template>

<script>
import { Search, List, Divider } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [Search.name]: Search,
    [List.name]: List,
    [Divider.name]: Divider
  },
  props: {
    // 所属模块
    moduleName: {
      type: [String, Number],
      default: ''
    },
    // 业务ID
    businessId: {
      type: [String, Number],
      default: ''
    },
    // 自定义数据类别
    category: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      searchForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: '',
        businessId: ''
      },
      loading: false,
      finished: false,
      tableList: {
        list: []
      },
      list: []
    }
  },
  watch: {
    // businessId: {
    //   handler: function (value) {
    //     if (value) {
    //       this.onSearch()
    //     }
    //   },
    //   immediate: true
    // }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.getPageList()
    },
    onLoad () {
      this.getPageList()
    },
    detail (id) {
      this.$router.push({ name: 'fileDetail', query: { id: id } })
    },
    getPageList () {
      const searchForm = this._.cloneDeep(this.searchForm)
      console.log(this.businessId)
      searchForm.moduleName = this.moduleName
      searchForm.businessId = this.businessId
      searchForm.category = this.category
      this.api.base.file.pageList(searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pageNum === 1 || this.tableList.pageNum === 0) {
          this.list = result.data.value.list
        } else {
          this.list = this.list.concat(result.data.value.list)
        }
        this.loading = false
        if (this.tableList.pageNum < this.tableList.pages) {
          this.searchForm.pageNum += 1
        } else {
          this.finished = true
        }
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.file-list {
  .file-item {
    font-size: 14px;
    margin-top: 15px;
    p {
      margin-left: 20px;
      >span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
      }
    }
    >p:nth-child(2) {
      margin-top: 8px;
      >span:nth-child(3) {
        margin-left: 10px;
      }
    }
    .van-divider {
      margin-top: 10px;
    }
  }
}
</style>
